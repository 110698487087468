<template>
    <div class="content">
        <el-form ref="form" :model="form" label-width="100px" inline="true">
            <el-form-item label="预算金额:">
                <el-input v-model="form.name" placeholder="请输入预算金额" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="分期期数：">
                <el-input v-model="form.name" size="mini" placeholder="请输入分期期数"></el-input>
            </el-form-item>
        </el-form>
        <el-form ref="form" :model="form" label-width="100px" inline="true">
            <el-form-item label="实际拨款金额:">
                <el-input v-model="form.name" placeholder="请输入实际拨款金额" size="mini"></el-input>
            </el-form-item>
            <el-form-item label="自筹金额：">
                <el-input v-model="form.name" size="mini" placeholder="请输入自筹金额"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <template>
            <el-table
                    :data="tableData"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="date"
                        label="拨款期次"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="name"
                        label="预付款日期"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="预算金额">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="账户支付金额">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="自筹金额">
                </el-table-column>
                <el-table-column
                        prop="address"
                        label="预付款比例（%）">
                </el-table-column>
            </el-table>
        </template>
    </div>
</template>

<script>
    export default {
        name: "step3",
        data() {
            return {
                form: {
                    name: ''
                },
                tableData: [{
                    date: '2016-05-02',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1518 弄'
                }, {
                    date: '2016-05-04',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1517 弄'
                }, {
                    date: '2016-05-01',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1519 弄'
                }, {
                    date: '2016-05-03',
                    name: '王小虎',
                    address: '上海市普陀区金沙江路 1516 弄'
                }]
            }
        }
    }
</script>

<style scoped lang="scss">
    .content {
        width: 1200px;
        background: #fff;
        margin: 0 auto;
        padding: 15px 111px;
    }
</style>